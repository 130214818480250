<template>
<div class="huanying">
    {{value}}, 欢迎登录！
</div>
</template>

<script scoped>

export default {
  name: 'welcome',
  components: {
  },
  data () {
    return {
      value: localStorage.yun_user
    }
  },
  created () {
  },
  methods: {
  }
}

</script>

<style scoped>
.huanying {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh; /* 使用100vh高度，使得div占满整个视口的高度 */
  font-size: 20px;
  text-align: center; /* 确保文本在其容器内居中 */
  transform: translateY(-20%); /* 将容器向上移动其高度的10% */
}
</style>
